import BackgroundImage from 'gatsby-background-image'
import { useIntl } from 'gatsby-plugin-intl'
import * as React from 'react'

import { Layout } from 'components'
import { LanguageSwitcher } from 'components/language-switcher'
import { Logo, LogoProps } from 'components/logo'
import SEO from 'components/SEO'
import fullLogo from 'images/logo_full.svg'
import oneLetterLogo from 'images/logo.svg'

import heroPattern from '../../images/hero-pattern-lg.png'

import * as styles from './EarlyAccess.module.less'

const linkToMySchedio = 'https://my.schedio.app' // 'https://oauth.schedio.app/oauth2/authorize?client_id=46179999-2f76-407e-b403-fe4e338371bc&response_type=code&redirect_uri=https%3A%2F%2Fmy.schedio.app%2Fcallback'
const linkToMySchedioNewAccount =
    'https://oauth.schedio.app/oauth2/register?tenantId=a7fb8af8-58a9-e4da-e310-683a3bbb6fa7&client_id=46179999-2f76-407e-b403-fe4e338371bc&nonce=&redirect_uri=https%3A%2F%2Fmy.schedio.app%2Fcallback&response_mode=&response_type=code&scope=&state=&timezone=Europe%2FMadrid&metaData.device.name=Windows%20Chrome&metaData.device.type=BROWSER&code_challenge=&code_challenge_method=&user_code='

const EarlyAccess: React.FC<any> = props => {
    return (
        <Layout
            layoutClassName="bg-gray-900"
            content={<EarlyAccessNew {...props} />}
            header={null}
            seo={<SEO title={'home'} />}
        />
    )
}
const EarlyAccessNew: React.FC = () => {
    const intl = useIntl()
    return (
        <body className="font-sans antialiased text-gray-900 bg-gray-900">
            <div className="relative min-h-screen overflow-hidden bg-gray-900">
                {/* <BackgroundImage
                    Tag="section"
                    className={`hidden ${styles.slidingBackgroundBig} lg:block absolute scroll-bg`}
                    fluid={imageData}
                    //   backgroundColor={`#040e18`}
                    style={{
                        height: '400%',
                        width: '400%',
                        top: '-25%',
                        left: '-100%',
                        backgroundSize: '800px auto',
                        backgroundImage: `url(${heroPattern})`,
                    }}
                /> */}
                <div
                    className={`hidden ${styles.slidingBackgroundBig} lg:block absolute scroll-bg`}
                    style={{
                        height: '400%',
                        width: '400%',
                        top: '-25%',
                        left: '-100%',
                        backgroundSize: '800px auto',
                        backgroundImage: `url(${heroPattern})`,
                    }}
                />
                <div
                    className={`relative min-h-screen lg:min-w-3xl xl:min-w-4xl lg:flex lg:items-center lg:justify-center lg:w-3/5 lg:py-20 lg:pl-8 lg:pr-8 bg-no-repeat`}
                    style={{
                        backgroundImage:
                            "url('https://tailwindui.com/img/angled-background.svg')",
                        backgroundSize: '100% auto',
                        backgroundPosition: '-5px -5px',
                    }}
                >
                    <div>
                        <div className="px-6 pt-8 pb-12 md:max-w-3xl md:mx-auto lg:mx-0 lg:max-w-none lg:pt-0 lg:pb-16">
                            <div className="flex items-center justify-between">
                                <Logo logo={fullLogo} alt="Schedio Logo" />

                                <div className="flex items-center space-x-4">
                                    <LanguageSwitcher />
                                    <a
                                        href={linkToMySchedio}
                                        target="_blank"
                                        className="text-sm font-semibold text-white focus:outline-none focus:underline"
                                    >
                                        {intl.formatMessage({
                                            id: 'header.signIn',
                                        })}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 md:max-w-3xl md:mx-auto lg:mx-0 lg:max-w-none">
                            <p className="text-sm font-semibold text-gray-300 uppercase tracking-wider">
                                {intl.formatMessage({
                                    id: 'earlyAccess.header.preTitle',
                                })}
                            </p>
                            <h1
                                className="mt-3 text-3xl leading-9 font-semibold font-display
                                text-white sm:mt-6 sm:text-4xl
                                sm:leading-10 xl:text-5xl xl:leading-12"
                            >
                                {intl.formatMessage({
                                    id: 'earlyAccess.header.titlePart1',
                                })}{' '}
                                <br className="hidden sm:inline" />
                                <span className="text-schedio-blue-500">
                                    {intl.formatMessage({
                                        id: 'earlyAccess.header.titlePart2',
                                    })}
                                </span>
                            </h1>
                            <p className="mt-2 text-lg leading-7 text-gray-300 sm:mt-3 sm:text-xl sm:max-w-xl xl:mt-4 xl:text-2xl xl:max-w-2xl">
                                {intl.formatMessage({
                                    id: 'earlyAccess.description',
                                })}
                            </p>
                            <div className="mt-6 sm:flex sm:mt-8 xl:mt-12">
                                <a
                                    href={linkToMySchedioNewAccount}
                                    target="_blank"
                                    className="w-full sm:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-semibold rounded-md text-gray-900 bg-white shadow-sm hover:text-gray-600 focus:outline-none focus:text-gray-600 transition ease-in-out duration-150 xl:text-lg xl:py-4"
                                >
                                    {intl.formatMessage({
                                        id: 'earlyAccess.earlyAccessButton',
                                    })}
                                </a>
                                {/* <a
                                    href="/components"
                                    className="w-full sm:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-semibold rounded-md text-gray-900 bg-white shadow-sm hover:text-gray-600 focus:outline-none focus:text-gray-600 transition ease-in-out duration-150 xl:text-lg xl:py-4"
                                >
                                    {intl.formatMessage({
                                        id: 'earlyAccess.exploreButton',
                                    })}
                                </a>
                                <a
                                    href="https://my.schedio.app"
                                    target="_blank"
                                    className="mt-4 sm:ml-4 sm:mt-0 w-full sm:w-auto inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-semibold rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-700 focus:outline-none focus:bg-gray-700 transition ease-in-out duration-150 xl:text-lg xl:py-4"
                                >
                                    {intl.formatMessage({
                                        id: 'earlyAccess.earlyAccessButton',
                                    })}
                                </a> */}
                            </div>
                        </div>
                        <div className="mt-8 sm:mt-12 relative h-64 overflow-hidden lg:hidden">
                            <div
                                className={`${styles.slidingBackgroundSmall} absolute scroll-bg`}
                                style={{
                                    height: '800%',
                                    width: '400%',
                                    top: '-100%',
                                    left: '-100%',
                                    backgroundSize: '400px auto',
                                    backgroundImage: `url(${heroPattern})`,
                                }}
                            />
                            {/*     <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80" alt="">
                             */}
                        </div>
                        {/* <div className="px-6 py-8 sm:pt-12 md:max-w-3xl md:mx-auto lg:mx-0 lg:max-w-full lg:py-0 lg:pt-24">
                            <p className="text-sm font-semibold text-gray-300 uppercase tracking-wider">
                                Designed and developed by
                            </p>
                            <div className="mt-4 sm:flex">
                                <a
                                    href="https://twitter.com/adamwathan"
                                    className="flex items-center no-underline"
                                >
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-12 w-12 rounded-full border-2 border-white"
                                            src="/img/adam.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-semibold text-white leading-tight">
                                            Adam Wathan
                                        </p>
                                        <p className="text-sm text-gray-500 leading-tight">
                                            Creator of Tailwind CSS
                                        </p>
                                    </div>
                                </a>
                                <a
                                    href="https://twitter.com/steveschoger"
                                    className="mt-6 sm:mt-0 sm:ml-12 flex items-center no-underline"
                                >
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-12 w-12 rounded-full border-2 border-white"
                                            src="/img/steve.jpg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <p className="font-semibold text-white leading-tight">
                                            Steve Schoger
                                        </p>
                                        <p className="text-sm text-gray-500 leading-tight">
                                            Author of Refactoring UI
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </body>
    )
}

export default EarlyAccess
